<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入物料号" suffix-icon="el-icon-search" v-model="compnum"></el-input>
      <el-input style="width: 200px" placeholder="请输入库位号" suffix-icon="el-icon-search" v-model="location"></el-input>
      <el-input style="width: 200px" placeholder="请输入备件名" suffix-icon="el-icon-search" v-model="name"></el-input>
      <el-date-picker
          v-model="startDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择起始日期"
      ></el-date-picker>
      <el-date-picker
          v-model="endDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择截止日期"
      ></el-date-picker>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>
    <div style="margin: 10px 0">
<!--      <el-upload action="http://39.106.92.106:9090/componentUpdate/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">-->
<!--        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>-->
<!--      </el-upload>-->
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
    </div>
    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
      <el-table-column prop="compnum" label="物料号" width="120"></el-table-column>
      <el-table-column prop="location" label="库位号" width="120"></el-table-column>
      <el-table-column prop="name" label="备件名"></el-table-column>
      <el-table-column prop="num" label="领用/入库数量" width="120"></el-table-column>
      <el-table-column prop="userName" label="操作人"></el-table-column>
      <el-table-column prop="takeTime" label="操作时间"></el-table-column>
      <el-table-column label="备件负责人">
        <template v-slot="scope">
          <span>{{ getEngNames(scope.row.compnum)  }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "ComponentUpdate",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      compnum : "",
      location : "",
      startDate : "",
      endDate : "",
      form: {},
      componentUsers: [],
      users: [],
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      baseURLdata: request.defaults.baseURL
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/componentUpdate/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
          compnum: this.compnum,
          location: this.location,
          startDate: this.startDate ? this.startDate.toISOString().split('T')[0] : '',
          endDate: this.endDate ? this.endDate.toISOString().split('T')[0] : ''
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/componentUser").then(res => {
        this.componentUsers = res.data
      });
      this.request.get("/user").then(res => {
        this.users = res.data
      });
    },
    reset() {
      this.name = ""
      this.compnum = ""
      this.location = ""
      this.startDate = ""
      this.endDate = ""
      this.load()
    },
    getEngNames(compnum) {
      const compUser = this.componentUsers.filter(item => item.compnum === compnum);
      return compUser.map(item => {
        const user = this.users.find(u => u.id === item.userId);
        return user ? user.nickname : '';
      }).join(', ');
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    exp() {
      window.open(this.baseURLdata+"/componentUpdate/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
